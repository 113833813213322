/** @format */

import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Details from '../Details';
import './task-list.scss';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateTaskTime } from '../../../../actions';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import moment from 'moment';
import CompletedTaskDetails from '../CompletedTaskDetails';
import Datatable from '../../../../containers/shared/Datatable';
import Status from '../../../../containers/shared/Status';
import { useTranslation } from 'react-i18next';
import { Constant, StatusLabel } from '../../StatusLabel';
import { Capitalize } from '../../../../Utility/Capitalize';
import CaretDownFillSvg from '../../../../svgs/caretDownFill';
import CaretUpFillSvg from '../../../../svgs/caretUpFill';
import PencilSquareSvg from '../../../../svgs/pencilSquare';
import BoxArrowLeftSvg from '../../../../svgs/boxArrowLeft';
import SweetAlert from 'react-bootstrap-sweetalert';
import { savedChanges } from '../../../../actions/helper';

const List = (props) => {
  const dispatch = useDispatch();
  const [startValue, setStartDate] = useState();
  const [endValue, setEndDate] = useState();
  const [selectedTaskId, setTaskId] = useState('');
  const [selectedEmployee, setEmployee] = useState('');
  const [tasks, setTasks] = useState([]);
  const [closeDatepicker, setCloseDatepicker] = useState({});
  const { t } = useTranslation('common');
  const [toggle, setToggle] = useState(false);
  const [alert, setAlert] = useState(false);

  const helper = useSelector((state) => state.helper);

  useEffect(() => {}, [helper]);

  useLayoutEffect(() => {
    let filteredTasks = [];
    if (props.type === 'admin') {
      filteredTasks = props.data.filter((task) => task.employee_id === props.adminId);
    } else {
      filteredTasks = props.data;
    }
    setTasks(filteredTasks);
  }, [props.data]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    if (closeDatepicker) {
      if (startValue && endValue && selectedTaskId && selectedEmployee) {
        dispatch(
          updateTaskTime({
            started_at: startValue,
            completed_at: endValue,
            taskId: selectedTaskId,
            selectedEmployee,
          })
        );
      }
    }
  }, [closeDatepicker]);

  useEffect(() => {
    if (props.errors && props.errors.length === 0) {
      setStartDate(null);
      setEndDate(null);
      setCloseDatepicker(false);
    }
  }, [props.data, props.updatedTaskIdByTime]);

  const statusCol = (props) => {
    let statusClass = 'status-default';
    let status = 'Unassigned';
    switch (props.row.original.status.toLowerCase()) {
      case 'completed':
        statusClass = 'status-complete';
        status = StatusLabel(t).completed;
        break;
      case 'assigned':
        statusClass = 'status-progress';
        status = StatusLabel(t).assigned;
        break;
      case 'running':
        statusClass = 'status-progress';
        status = StatusLabel(t).running;
        break;
      case 'invoiced':
        statusClass = 'status-invoiced';
        status = StatusLabel(t).invoiced;
        break;
      default:
        statusClass = 'status-default';
        status = StatusLabel(t).unassigned;
    }
    return <Status status={status} statusClass={statusClass} />;
  };

  const handleStartClose = (type, e, taskId, selectedEmployee, date, status) => {
    if (e && status.toUpperCase() !== Constant.completed) {
      if (type === 'start') {
        setStartDate(e.toDate());
      }
      if (date !== null) {
        setEndDate(new Date(date));
      }
      setTaskId(taskId);
      setEmployee(selectedEmployee);
      setCloseDatepicker(true);
    }
  };

  const handleEndClose = (type, e, taskId, selectedEmployee, date, status) => {
    if (e && status.toUpperCase() !== Constant.completed) {
      if (type === 'end') {
        setEndDate(e.toDate());
      }
      if (date !== null) {
        setStartDate(new Date(date));
      }
      setTaskId(taskId);
      setEmployee(selectedEmployee);
      setCloseDatepicker(true);
    }
  };

  const onBack = () => {
    if (helper.unSavedChanges) {
      const getAlert = () => (
        <SweetAlert
          danger
          showCancel
          closeOnClickOutside={true}
          onCancel={() => {
            setAlert(null);
          }}
          title={`${t('task.details.messages.delete_modal_title')}`}
          onConfirm={() => {
            setToggle(false);
            dispatch(savedChanges());
            setAlert(null);
          }}
          confirmBtnText={t('button.confirm')}
          cancelBtnText={t('button.cancel')}
          confirmBtnStyle={{ background: '#71BF5D', color: 'white' }}
          cancelBtnStyle={{ background: '#D43B3B', color: 'white', textDecoration: 'none' }}
        >
          {t('task.details.messages.unsaved')}
        </SweetAlert>
      );
      setAlert(getAlert());
    } else {
      setToggle(false);
    }
  };

  const onRowCollapse = ({ onClick }) => {
    if (helper.unSavedChanges) {
      const getAlert = () => (
        <SweetAlert
          danger
          showCancel
          closeOnClickOutside={true}
          onCancel={() => {
            setAlert(null);
          }}
          title={`${t('task.details.messages.delete_modal_title')}`}
          onConfirm={() => {
            setToggle(false);
            onClick();
            dispatch(savedChanges());
            setAlert(null);
          }}
          confirmBtnText={t('button.confirm')}
          cancelBtnText={t('button.cancel')}
          confirmBtnStyle={{ background: '#71BF5D', color: 'white' }}
          cancelBtnStyle={{ background: '#D43B3B', color: 'white', textDecoration: 'none' }}
        >
          {t('task.details.messages.unsaved')}
        </SweetAlert>
      );
      setAlert(getAlert());
    } else {
      onClick();
    }
  };

  const renderRowSubComponent = React.useCallback(
    ({ row }) =>
      !toggle && row.isExpanded && (row.original.status.toUpperCase() === Constant.invoiced || row.original.status.toUpperCase() === Constant.completed) ? (
        <CompletedTaskDetails {...row.original} />
      ) : (
        <Details row={row.original} productLists={props.products} kilometer_service_driven={row.original.kilometer_service_driven} setToggle={setToggle} />
      ),
    [props.products, toggle, helper]
  );

  // onClick={(e) => handleToggle(e, props.row)}
  const columns = React.useMemo(
    () => [
      {
        Header: () => {
          return `${t('task.employee_task_list.heading_one')}`;
        },
        accessor: 'title',
        className: 'Tasks',
        Cell: (props, { row }) => (
          <div className='text-left custom-task-width-mytasks content-td'>
            <div className='mb-2 d-flex align-items-center'>
              <h3 className='d-flex flex-row'>
                {Capitalize(props.row.original.client.name)}
                <span>
                  <div
                    {...props.row.getToggleRowExpandedProps()}
                    onClick={() =>
                      onRowCollapse(props.row.getToggleRowExpandedProps())
                    }
                    className='custom-toggle-icon'
                  >
                    {props.row.isExpanded ? (
                      <CaretUpFillSvg />
                    ) : (
                      <CaretDownFillSvg />
                    )}
                  </div>
                </span>
              </h3>

              {props.row.original.status.toUpperCase() === Constant.completed &&
              props.row.isExpanded ? (
                <div className='d-flex align-items-center'>
                  {toggle ? (
                    <div
                      className='edit-icon d-flex align-items-center'
                      onClick={onBack}
                    >
                      <div className='edit-icon d-flex align-items-center'>
                        {t('button.back')}{' '}
                        <BoxArrowLeftSvg className='primary-btn-fill editIcon' />
                      </div>
                      {/* <DoneIcon className="edit-icon" fontSize="small" id={props.row.original.id} name={props.row.original.id}  /> */}
                    </div>
                  ) : (
                    <div
                      className='edit-icon d-flex align-items-center'
                      onClick={() => setToggle(true)}
                    >
                      <div className='edit-icon d-flex align-items-center'>
                        {t('button.edit')}
                        <PencilSquareSvg
                          className='primary-btn-fill editIcon'
                          fontSize='small'
                          id={props.row.original.id}
                          name={props.row.original.id}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>

            <div>
              {Capitalize(props.row.original.client.address)}
              {props.row.original.client.zipcode
                ? `, ${props.row.original.client.zipcode}`
                : ''}
              {props.row.original.client.postal
                ? `, ${props.row.original.client.postal}`
                : ''}
            </div>
          </div>
        ),
      },
      {
        Header: () => {
          return `${t('task.employee_task_list.heading_two')}`;
        },
        accessor: 'date_of_task_arrival',
        className: 'Arrived',
        Cell: (props) => (
          <div className='white-border custom-task-w-10 content-td'>
            <div>
              <h6 className='d-md-none d-block'>
                {t('task.employee_task_list.heading_two')}
              </h6>
              {new Date(props.row.original.created_at).toLocaleDateString()}
            </div>
          </div>
        ),
      },
      {
        Header: () => {
          return `${t('task.employee_task_list.heading_three')}`;
        },
        accessor: 'completed_date',
        className: 'Completed',
        Cell: (props) => {
          let local = null;
          if (
            props.row.original.timings.length > 0 &&
            props.row.original.timings[0].stopped_at !== null
          ) {
            const utc = moment.utc(props.row.original.timings[0].stopped_at);
            local = new Date(utc.local());
          }
          return (
            <div className='white-border custom-task-w-10 content-td'>
              <div>
                <h6 className='d-md-none d-block'>
                  {t('task.employee_task_list.heading_three')}
                </h6>
                {local !== null ? `${local.toLocaleDateString()}` : '----'}
              </div>
            </div>
          );
        },
      },
      {
        Header: () => {
          return `${t('task.employee_task_list.heading_four')}`;
        },
        accessor: 'started_at',
        className: 'date-column',
        Cell: (props) => {
          let local = null;
          if (
            props.row.original.timings.length > 0 &&
            props.row.original.timings[0].started_at !== null
          ) {
            const utc = moment.utc(props.row.original.timings[0].started_at);
            local = new Date(utc.local());
          }
          return (
            <div className='white-border custom-task-w-20 content-td'>
              <h6 className='d-md-none d-block'>
                {t('task.employee_task_list.heading_four')}
              </h6>
              {props.row.original.status.toLowerCase() === 'completed' ||
              props.row.original.status.toLowerCase() === 'invoiced' ? (
                <div>
                  {local !== null
                    ? `${local.toLocaleDateString()} ${local.toLocaleTimeString(
                        [],
                        { hour: '2-digit', minute: '2-digit', hour12: false }
                      )}`
                    : '----'}
                </div>
              ) : (
                <Datetime
                  onChange={(e) => {
                    setStartDate(e.toDate());
                    setTaskId(props.row.original.id);
                  }}
                  onOpen={(e) => {
                    setCloseDatepicker(false);
                  }}
                  onClose={(e) =>
                    handleStartClose(
                      'start',
                      e,
                      props.row.original.id,
                      props.row.original.employee_id,
                      props.row.original.timings.length > 0
                        ? new Date(
                            moment
                              .utc(props.row.original.timings[0].stopped_at)
                              .local()
                          )
                        : null,
                      props.row.original.status
                    )
                  }
                  value={startValue}
                  initialValue={local !== null ? local : startValue}
                  timeFormat={'HH:mm'}
                  dateFormat={'DD/MM/YYYY'}
                />
              )}
            </div>
          );
        },
      },
      {
        Header: () => {
          return `${t('task.employee_task_list.heading_five')}`;
        },
        accessor: 'completed_at',
        className: 'date-column',

        Cell: (props) => {
          let local = null;
          if (
            props.row.original.timings.length > 0 &&
            props.row.original.timings[0].stopped_at !== null
          ) {
            const utc = moment.utc(props.row.original.timings[0].stopped_at);
            local = new Date(utc.local());
          }
          return (
            <div className='white-border custom-task-w-20 content-td'>
              <h6 className='d-md-none d-block'>
                {t('task.employee_task_list.heading_five')}
              </h6>
              {props.row.original.status.toLowerCase() === 'completed' ||
              props.row.original.status.toLowerCase() === 'invoiced' ? (
                <div>
                  {local !== null
                    ? `${local.toLocaleDateString()} ${local.toLocaleTimeString(
                        [],
                        { hour: '2-digit', minute: '2-digit', hour12: false }
                      )}`
                    : '----'}
                </div>
              ) : (
                <Datetime
                  onChange={(e) => {
                    setEndDate(e.toDate());
                    setTaskId(props.row.original.id);
                  }}
                  onOpen={(e) => {
                    setCloseDatepicker(false);
                  }}
                  onClose={(e) =>
                    handleEndClose(
                      'end',
                      e,
                      props.row.original.id,
                      props.row.original.employee_id,
                      props.row.original.timings.length > 0
                        ? new Date(
                            moment
                              .utc(props.row.original.timings[0].started_at)
                              .local()
                          )
                        : null,
                      props.row.original.status
                    )
                  }
                  value={endValue}
                  initialValue={local !== null ? local : endValue}
                  timeFormat={'HH:mm'}
                  dateFormat={'DD/MM/YYYY'}
                />
              )}
            </div>
          );
        },
      },
      {
        Header: () => {
          return `${t('task.employee_task_list.heading_six')}`;
        },
        accessor: 'status',
        className: 'Status',
        Cell: (props) => (
          <div className='custom-task-w-1 content-td'>
            <h6 className='d-md-none d-block'>
              {t('task.employee_task_list.heading_six')}
            </h6>
            {statusCol(props)}
          </div>
        ),
      },
    ],
    [toggle, helper]
  );

  const ourGlobalFilterFunction = useCallback((rows, ids, query) => {
    return rows.filter((row) => {
      const name = row.original.client.name.toLowerCase();
      const address = row.original.client.address.toLowerCase();
      const status = row.original.status.toLowerCase();
      return name.includes(query.toLowerCase()) || address.includes(query.toLowerCase()) || status.includes(query.toLowerCase());
    });
  }, []);
  return (
    <>
      {alert}
      <Datatable tableClass={'employee-task-list'} toggle={toggle} isPaginated={true} globalFilterFunction={ourGlobalFilterFunction} isFiltered={true} renderRowSubComponent={renderRowSubComponent} data={tasks} isExpanded={true} columns={columns} />
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.taskList.tasks,
  updatedTaskIdByTime: state.taskList.updatedTaskId,
  errors: state.response.errors,
});
export default connect(mapStateToProps)(List);
