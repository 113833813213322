import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { markSingleNotificationsRead } from '../../../actions';
import Datatable from '../../../containers/shared/Datatable';
import Details from '../Details';
import PreLoader from '../../shared/PreLoader';
import '../notification.scss'

const List = ({notifications}) => {
    

    const {t} = useTranslation('common')
    const dispatch = useDispatch()
    const notificationId = useSelector(state => state.response.notificationId)
    const notificationUpdateSuccess = useSelector(state => state.response.notificationReadSuccess)

    const renderRowSubComponent = React.useCallback(
        ({ row }) => 
            <Details data={row.original}/>,
        []
    );
      
    const columns = React.useMemo(
        () => [
          {
            // Make an expander cell
            Header: () => null, // No header
            id: 'expander', // It needs an ID
            Cell: ({ row }) => {
              return (
                  <>
                  {
                      row.original.data.type === 'attention' ?
                        <div {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? <img src="/images/arrow-down.svg" alt=""/> : <img src="/images/arrow-up.svg" alt=""/>}
                        </div>
                        :
                        ""
                  }
                  </>
              );
            },
          },
          {
            Header: () => {
              return `${t('notifications.header.notification')}`
            },
            accessor: 'title',
            className: "Tasks",
            Cell: (props) => {
              return (
                <div className='text-left'>
                  <div className='d-flex align-items-center'>
                        <div className={props.row.original.read_at === null ? 'notification-icon' : ''}>
                          <img
                            src='/images/ionic-ios-notifications.svg'
                            alt='notification_icon'
                          />
                        </div>
                        <p className='message'>
                          {props.row.original.data.data}
                          &nbsp;
                          {
                            props.row.original.read_at === null ? <button
                              className='mark-btn'
                              onClick={() => dispatch(markSingleNotificationsRead(props.row.original.id))}
                            >
                              {t('notifications.mark_as_read')}
                            </button>
                            :
                            ""
                          }
                        </p>
                        <div className="loader-container">
                          {
                            notificationId === props.row.original.id && notificationUpdateSuccess ? "" 
                            : notificationId === props.row.original.id && notificationUpdateSuccess === false ? <PreLoader /> : ""
                          }
                        </div>
                      </div>
                </div>
              )
            }
          },
          {
            Header: () => {
              return `${t('notifications.header.arrived_at')}`
            },
            accessor: 'date_of_task_arrival',
            className: "Arrived",
            Cell: (props) => (
              <div className='white-border'>
                <div className='d-flex align-items-center justify-content-center'>
                    <p className="mr-3">{new Date(props.row.original.created_at).toLocaleDateString()}</p>
                    <p>{new Date(props.row.original.created_at).toLocaleTimeString()}</p>
                </div>
              </div>
            ),
          },
        ],
        [notificationId, notificationUpdateSuccess]
      );


    
    return (
            <Datatable tableClass={'notification-table'} rowClass={'notification'} headerClass={'notification-time'} renderRowSubComponent={renderRowSubComponent} data={notifications} isExpanded={true} columns={columns} />
    )
}

export default List