import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datatable from '../../../containers/shared/Datatable';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { assignTask } from '../../../actions';
import Status from '../../../containers/shared/Status';
import './SummaryList.scss';
import { useTranslation } from 'react-i18next';
import PreLoader from '../../shared/PreLoader';
import Details from '../Details';
import DropDown from '../../shared/DropDown';
import { Constant, StatusLabel } from '../../shared/StatusLabel';
import { Capitalize } from '../../../Utility/Capitalize';
import CaretUpFillSvg from '../../../svgs/caretUpFill';
import CaretDownFillSvg from '../../../svgs/caretDownFill';
import EditIcon from '@material-ui/icons/Edit';
import D from '../../../components/shared/Tasks/Details/index';
import DoneIcon from '@material-ui/icons/Done';

const List = ({ filterType, products }) => {
  const tasks = useSelector((state) => state.taskList.tasks);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.employees);
  const taskId = useSelector((state) => state.response.taskId);
  const assignTaskSuccess = useSelector((state) => state.response.assignTaskSuccess);
  const [newTasks, setNewTasks] = useState([]);
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation('common');

  useLayoutEffect(() => {
    filteredTasks();
  }, [tasks]);

  useEffect(() => {
    filteredTasks();
  }, [filterType]);

  useEffect(() => {
    if (tasks.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [tasks]);

  // const renderRowSubComponent = React.useCallback(
  //   ({ row }) => <Details {...row.original} />,
  //   []
  // );
  const renderRowSubComponent = React.useCallback(
    ({ row }) => {
      if (!toggle && row.isExpanded) {
        return <Details {...row.original} />;
      } else {
        return <D row={row.original} productLists={products} />;
      }
    },
    [products, toggle]
  );

  const filteredTasks = () => {
    let taskArray = [];
    if (filterType === 'new_task') {
      taskArray = tasks.filter((task) => task.status.toUpperCase() === 'NEW REQUEST');
    } else if (filterType === 'pending_task') {
      taskArray = tasks.filter((task) => task.status.toUpperCase() === 'ASSIGNED');
    } else if (filterType === 'need_attention') {
      taskArray = tasks.filter((task) => task.require_attention === 1);
    } else if (filterType === 'invoiced_task') {
      taskArray = tasks.filter((task) => task.status.toUpperCase() === 'INVOICED');
    } else if (filterType === 'uninvoiced_task') {
      taskArray = tasks.filter((task) => task.status.toUpperCase() === 'COMPLETED');
    }
    setNewTasks(taskArray);
  };

  const selectedEmployee = (taskEmployeeId) => {
    const selectedEmployee = employees.employeesList.find((employee) => employee.id === taskEmployeeId);
    return selectedEmployee ? selectedEmployee.name : '';
  };

  const changeEmployee = (taskId, employeeId) => {
    dispatch(assignTask(taskId, employeeId));
  };

  const statusCol = (props) => {
    let statusClass = 'status-default';
    let status = 'Unassigned';
    switch (props.row.original.status.toUpperCase()) {
      case 'completed'.toUpperCase():
        statusClass = 'status-complete';
        status = StatusLabel(t).completed;
        break;
      case 'assigned'.toUpperCase():
        statusClass = 'status-progress';
        status = StatusLabel(t).assigned;
        break;
      case 'running'.toUpperCase():
        statusClass = 'status-progress';
        status = StatusLabel(t).running;
        break;
      case 'invoiced'.toUpperCase():
        statusClass = 'status-invoiced';
        status = StatusLabel(t).invoiced;
        break;
      default:
        statusClass = 'status-default';
        status = StatusLabel(t).unassigned;
    }
    return <Status status={status} statusClass={statusClass} />;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => {
          return `${t('task.list.heading_one')}`;
        },
        accessor: 'title',
        className: 'Tasks',
        Cell: (props, { row }) => {
          return (
            <div className='text-left custom-task-width pl-3'>
              <div className={props.row.original.require_attention === 1 ? `mb-2 d-flex align-items-center justify-content-between required-column` : `mb-2 d-flex align-items-center`}>
                <h3 className='d-flex flex-row'>
                  {Capitalize(props.row.original.client.name)}
                  <span>
                    <div {...props.row.getToggleRowExpandedProps()} className='custom-toggle-icon'>
                      {props.row.isExpanded ? <CaretUpFillSvg /> : <CaretDownFillSvg className='ml-2' />}
                    </div>
                  </span>
                </h3>

                {props.row.isExpanded &&
                  props.row.original.status.toUpperCase() === Constant.completed &&
                  (toggle ? (
                    <div className='edit-icon d-flex align-items-center' onClick={() => setToggle(false)}>
                      <span className='edit-icon-title'> {t('button.save')} </span>
                      <DoneIcon className='edit-icon' fontSize='small' id={props.row.original.id} name={props.row.original.id} />
                    </div>
                  ) : (
                    <div className='edit-icon d-flex align-items-center' onClick={() => setToggle(true)}>
                      <span className='edit-icon-title'> {t('task.details.edit_as_employee')} </span>
                      <EditIcon className='edit-icon' fontSize='small' id={props.row.original.id} name={props.row.original.id} />
                    </div>
                  ))}

                {props.row.original.require_attention === 1 ? <div className='require-text'>{t('require_attention')}</div> : ''}
              </div>

              <div>
                {props.row.original.client.address ? `${Capitalize(props.row.original.client.address)}` : ''}
                {props.row.original.client.zipcode ? `, ${props.row.original.client.zipcode}` : ''}
                {props.row.original.client.postal ? `, ${props.row.original.client.postal}` : ''}
              </div>
              {alert}
            </div>
          );
        },
      },
      {
        Header: () => {
          return `${t('task.list.heading_two')}`;
        },
        accessor: 'date_of_task_arrival',
        className: 'Arrived',
        Cell: (props) => (
          <div className='white-border custom-task-w-15 custom-arrived'>
            <h6 className='d-md-none d-block'>{t('task.list.heading_two')}</h6>
            <div>{new Date(props.row.original.created_at).toLocaleDateString()}</div>
          </div>
        ),
      },
      {
        Header: () => {
          return `${t('task.list.heading_three')}`;
        },
        className: 'Completed',
        accessor: 'completed_date',
        Cell: (props) => {
          let local = null;
          if (props.row.original.timings.length > 0) {
            const utc = moment.utc(props.row.original.timings[0].stopped_at);
            local = utc.local();
          }
          return (
            <div className='white-border custom-task-w-15 custom-completed'>
              <h6 className='d-md-none d-block'>{t('task.list.heading_three')}</h6>
              <div>{props.row.original.timings.length > 0 && props.row.original.status.toLowerCase() === 'completed' ? local.format('DD.MM.YYYY HH:mm:ss') : '----'}</div>
            </div>
          );
        },
      },
      {
        Header: () => {
          return `${t('task.list.heading_four')}`;
        },
        accessor: 'worker',
        className: 'Worker',
        Cell: (props) => (
          <div className='white-border custom-task-w-15 custom-worker'>
            <h6 className='d-md-none d-block'>{t('task.list.heading_four')}</h6>
            {props.row.original.status.toUpperCase() === 'COMPLETED' || props.row.original.status.toUpperCase() === 'INVOICED' ? (
              <span>{selectedEmployee(props.row.original.employee_id)}</span>
            ) : (
              <DropDown items={employees.employeesList} searchBy={'name'} inputClass={'user-search'} searchType={'string'} selected={selectedEmployee(props.row.original.employee_id)} placeholder={'Hae käyttäjää'} onChangeHandle={(id) => changeEmployee(props.row.original.id, id)} />
            )}
          </div>
        ),
      },
      {
        Header: () => {
          return `${t('task.list.heading_five')}`;
        },
        accessor: 'status',
        className: 'Status',
        Cell: (props) => (
          <div className='custom-task-w-15 custom-status'>
            <h6 className='d-md-none d-block'>{t('task.list.heading_five')}</h6>
            {statusCol(props)}
          </div>
        ),
      },
    ],
    [taskId, assignTaskSuccess, toggle]
  );

  const ourGlobalFilterFunction = useCallback((rows, ids, query) => {
    return rows.filter((row) => {
      const name = row.original.client.name.toLowerCase();
      const address = row.original.client.address.toLowerCase();
      const status = row.original.status.toLowerCase();
      const employee_id = row.original.employee_id;
      const employeeIds = employees.employeesList
      .filter((emp) => emp.name?.toLowerCase().includes(query?.toLowerCase()))
      .map((emp) => emp.id)
      
      return (
        name.includes(query.toLowerCase()) ||
        address.includes(query.toLowerCase()) ||
        status.includes(query.toLowerCase()) ||
        employeeIds.includes(employee_id)
      )
      });
  }, [employees]);

  return <>{isLoading ? <PreLoader /> : <Datatable isPaginated={true} isExpanded={true} isFiltered={true} renderRowSubComponent={renderRowSubComponent} globalFilterFunction={ourGlobalFilterFunction} tableClass={'task-list'} data={newTasks} columns={columns} />}</>;
};

export default List;
