/** @format */

import React, { useEffect, useState, useLayoutEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import ModalPopup from '../shared/ModalPopup';
import Form from './Form';
import './Product.scss';
import SweetAlert from 'react-bootstrap-sweetalert';
import { deleteProduct, fetchProduct, syncProducts } from '../../actions';
import Datatable from '../../containers/shared/Datatable';
import { useTranslation } from 'react-i18next';
import CachedIcon from '@material-ui/icons/Cached';
import PreLoader from '../shared/PreLoader';

const Products = ({products, isAdmin, modalClose, productSync, loader}) => {

  const [modalOpen, setModalOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [product, setProduct] = useState({})
  const [action, setAction] = useState("create");
  const [sync, setSync] = useState();
  const dispatch = useDispatch();
  const{t} = useTranslation('common')

  useLayoutEffect(() => {
    dispatch({type: 'API_IS_FETCHING'})
    dispatch(fetchProduct())
  },[])

  useEffect(() => {
    if(products.length > 0){
      dispatch({type: 'API_FETCHED'})
    }
  },[products])

  useEffect(() => {
    if(modalClose){
      handleModalOpen(!modalClose)
    }
  },[modalClose])

  useEffect(() => {
    setSync(productSync)
  },[productSync])

  const handleModalOpen = (status) => {
    setModalOpen(status)
  }

  const handleDelete = (productId) => {
    const getAlert = () => (
      <SweetAlert 
        danger 
        closeOnClickOutside={true}
        onCancel={() => setAlert(null)}
        title={t('product.delete.heading')} 
        onConfirm={() => {
          dispatch(deleteProduct(productId))
          setAlert(null)
        }}
        confirmBtnStyle={{background: "#71BF5D"}}
      >
        {t('product.delete.content')}
      </SweetAlert>
    );
    setAlert(getAlert())
  }

  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => {
          return `${t('product.list.heading_one')}`
        },
        accessor: 'title',
        className: 'product-name',
        Cell: ({ row }) => {
          return (
            <div className='text-left'>
              <div className='mb-2 d-flex align-items-center'>
                <div className='product-name-container'>
               
                  <p className="product-title"> {row.original.name} </p>
                </div>
                {/* {isAdmin === 1 || isAdmin === '1' ? (
                  <div className='product-btn-container'>
                    <button className='product-btn btn-edit' onClick={() => {
                      setAction("edit")
                      setProduct(row.original)
                      setModalOpen(!modalOpen)
                    }}>{t('button.edit')}</button>
                    <button className='product-btn btn-delete' onClick={() => handleDelete(row.original.id)}>{t('button.delete')}</button>
                  </div>
                ) : (
                  ''
                )} */}
              </div>
            </div>
          )
        }
      },
      {
        Header: () => {
          return `${t('product.list.heading_two')}`
        },
        className: 'product-id',
        accessor: 'product_id',
        Cell: (props) => (
          <div className=' id-model-container'>
            <div className="white-border">
            <h6 className="d-sm-none d-block">{t('product.list.heading_two')}</h6>
              <p>{props.row.original.id}</p>
            </div>
          </div>
        ),
      },
      {
        Header: () => {
          return `${t('product.list.heading_three')}`
        },
        className: 'product-model',
        accessor: 'model',
        Cell: (props) => (
          <div className='white-border'>
             <h6 className="d-sm-none d-block">{t('product.list.heading_three')}</h6>
            <div>{props.row.original.model}</div>
          </div>
        ),
      },
    ],
    []
  );

  const ourGlobalFilterFunction = useCallback(
    (rows, ids, query) => {
        return rows.filter((row) => { 
            const name = row.original.name.toLowerCase()
            const model = row.original.model.toLowerCase()
            return name.includes(query.toLowerCase()) ||
            model.includes(query.toLowerCase())
          } 
        );
    },
    [],
  );

return (
  <div className='product-container'>
    <h1>{t('product.heading')}</h1>
    <div className='btn-container product-btn-area'>
      {/* <button className='project-btn' onClick={() => {
        setAction("create")
        setModalOpen(!modalOpen)
      }}>+ {t('product.heading')}</button> */}
      <button className='project-btn' onClick={() => dispatch(syncProducts())}>
      { sync ? 
          <>
            <CachedIcon/> 
            {t('button.re_sync_button') }
          </>
          : 
          `... ${t('button.synced_button')}`
      }
      </button>
    </div>
    {
      loader ? <PreLoader /> : 
      <>
      <Datatable tableClass={"task-list"} globalFilterFunction={ourGlobalFilterFunction} isFiltered={true} isPaginated={true} data={products} isExpanded={true} columns={columns} />
        {alert}
        <ModalPopup open={modalOpen} header={action === "create" ? `${t('product.add.heading')}` : `${t('product.edit.heading')}`} setModalClose={setModalOpen}>
            <Form productDetails={
              action === 'edit' ? product : false
            } action={action} isAdmin={isAdmin}/>
        </ModalPopup>
      </>
    }
  </div>
);

}
const mapStateToProps = (state) => ({
  isAdmin: state.login.isAdmin,
  products: state.products.productsList,
  productSync: state.products.productSynced,
  modalClose: state.response.modalClose,
  loader: state.response.isLoading
});

export default connect(mapStateToProps)(Products);
